/**
 * Determines Whether Or Not An Email Is Valid
 *
 * @param email - The Email Address Being Verified
 * @returns Whether Or Not The Email Is Valid
 */
export const validateEmail = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

/**
 * Determines Whether Or Not A Password Is Valid
 *
 * @param password - The Password Being Verified
 * @returns Whether Or Not The Password Is Valid
 */
export const validatePassword = (password: string) => {
  // Contains Special Character
  const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
    password
  );

  // Password Length
  const atLeast8Characters = password.length >= 8;

  // Contains Number
  const hasNumber = /\d/.test(password);

  // Contains A Capital Letter
  const hasCapitalLetter = /[A-Z]/.test(password);

  // Returning All Checked Values
  return (
    hasSpecialCharacter && atLeast8Characters && hasNumber && hasCapitalLetter
  );
};

export const validateOfficeAddress = (address: string) =>{
  const hasNumber = /\d/.test(address);
  const hasLetters = /[a-zA-Z]/.test(address);
  return hasNumber && hasLetters;
};

// Check If A String Is Empty
export const isEmpty = (str: string | any[]): boolean => str.length === 0;

// Lowercase All Letters Except The First One
export const lowerCaseAllWordsExceptFirstLetters = (string: string) =>
  string.replaceAll(
    /\S*/g,
    word => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );

/**
 * Set A Timeout In An Asynchronous Environment
 *
 * @param milli - The Number Of Milliseconds To Timeout For
 */
export const setTimeoutAsync = (milli: number): Promise<void> =>
  new Promise(res => setTimeout(res, milli));

/**
 * Capitalize The First Letter Of A String
 *
 * @param string - The String To Capitalize The First Letter Of
 * @returns The Formatted String
 */
export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Check If A URL Is Valid
 * 
 * @param urlString - The String To Test For A URL
 * @returns - A Boolean Representing Whether The URL Is Valid Or Not
 */
export const isValidUrl = (urlString: string) => {
  try {
    // Reurning Whether The URL Accepts The String
    return Boolean(new URL(urlString));
  } catch {
    // Returning False If An Error Is False
    return false;
  }
};
