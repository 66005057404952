import { Avatar } from "@assets";
import useDepartments from "@hooks/departments";
import React from "react";
import { twMerge } from "tailwind-merge";
// Import useOffices from "@hooks/offices";

interface EmployeeCellProps {
  // The Selected Employees
  selected: string[];

  // Set Employees
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;

  // The User
  user: Drivn.User;
}

const EmployeeCell = (props: EmployeeCellProps) => {
  /**
   * Toggle Selecting The User
   */
  const toggleSelect = () => {
    if (!props.selected.includes(props.user.uid)) {
      // Adding The User Id
      props.setSelected([...props.selected, props.user.uid]);
    } else {
      // Temporary Selected Value
      const newSelected = props.selected;

      // Removing The User Id
      newSelected.splice(newSelected.indexOf(props.user.uid));

      // Setting The New Selected
      props.setSelected(newSelected);
    }
  };

  // The Offices
  // Const { findCurrent } = useOffices();

  // The Departments
  const { findCurrentDep } = useDepartments();

  return (
    <tr className="border-b dark:border-gray-600 transition-all hover:bg-bg">
      <td className="px-4 py-3 w-4">
        <div className="flex items-center">
          <input
            id="checkbox-table-search-1"
            type="checkbox"
            onClick={toggleSelect}
            className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="checkbox-table-search-1" className="sr-only">
            checkbox
          </label>
        </div>
      </td>
      <th
        scope="row"
        className="px-4 py-3 font-medium whitespace-nowrap text-black"
      >
        <div className="flex items-center mr-3">
          <img
            src={props.user.image ?? Avatar}
            alt="avatar"
            className="h-8 w-auto mr-3 rounded-full"
          />
          {`${props.user.firstName} ${props.user.lastName}`}
        </div>
      </th>
      <td className="px-4 py-3">
        <div
          data-tooltip-target="tooltip-role-1"
          className={twMerge(
            "inline-flex items-center bg-opacity-30 text-black text-xs font-medium px-2 py-0.5 rounded",
            props.user.isOwner
              ? "bg-blue-800"
              : props.user.isAdmin && "bg-purple-800"
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
            />
          </svg>
          {props.user.isOwner
            ? "Owner"
            : props.user.isAdmin
            ? "Administrator"
            : "Employee"}
        </div>
        {/* H<div
          id="tooltip-role-1"
          role="tooltip"
          className="absolute z-10 max-w-[14rem] invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          <h6 className="leading-none text-sm font-medium dark:text-white mb-1">
            Administrator
          </h6>
          <p className="leading-tight text-xs font-medium text-gray-500 dark:text-gray-400">
            Administrators are responsible for managing the entire system.
          </p>
          <div className="tooltip-arrow" data-popper-arrow="" />
        </div> */}
      </td>
      <td className="px-4 py-3 whitespace-nowrap">
        <div className="flex items-center space-x-1.5">
          {/* Props.user.officeId === undefined
            ? "Hasn't Selected"
            : findCurrent(props.user.officeId)?.name ?? "Loading..." */}
        </div>
      </td>
      <td className="px-4 py-3 font-medium text-black whitespace-nowrap">
        {props.user.departmentId === undefined
          ? "Hasn't Selected"
          : findCurrentDep(props.user.departmentId)?.name ?? ""}
      </td>
      <td className="px-4 py-3 font-medium text-black whitespace-nowrap">
        {/* Props.user.anonymous ? "Yes" : "No" */}
      </td>
      <td className="px-4 py-3 font-medium text-black whitespace-nowrap">
        <div className="flex items-center">
          <div
            className={twMerge(
              "h-3 w-3 rounded-full mr-2",
              props.user.removed ? "bg-red-500" : "bg-green-500"
            )}
          />
          {props.user.removed ? "Removed" : "Active"}
        </div>
      </td>
    </tr>
  );
};

export default EmployeeCell;
