import React, { useEffect, useState } from "react";

// Routing
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

// Helpers
import {
  Routes,
  capitalizeFirstLetter,
} from "@util";
import useEmployees from "@hooks/employees";

// Subviews
import { setDocumentTitle } from "@hooks/document-title";
import EmployeeCell from "./subviews/employee-cell";
import { useDarkMode } from "@hooks/dark-mode";
import { Slab } from "react-loading-indicators";

const Employees = () => {
  // The Search Params
  const [searchParams] = useSearchParams();

  // Selected Users
  const [selected, setSelected] = useState<string[]>([]);

  // The Employees Hook
  const employees = useEmployees();

  const handleNext = () => {
    const newOffset = (employees.currentPage + 1) * employees.limit;
    employees.setOffset(newOffset);
    employees.setCurrentPage(employees.currentPage + 1);
  };

  const handlePrev = () => {
    const newCurrentPage = Math.max(0, employees.currentPage - 1);
    employees.setCurrentPage(newCurrentPage);
    const newOffset = newCurrentPage * employees.limit;
    employees.setOffset(newOffset);
  }; 

  // Called Before The View Loads
  useEffect(() => {
    // Setting The Document Title
    setDocumentTitle("Employees");
  }, []);

  // The Current Dark Mode
  const dark = useDarkMode();

  // The Dispatch

  const allEmployees = employees.employees.flatMap(set => set.employees);

  return (
    <>
      {employees.loading && employees.total < 0 ? (
        <div className="w-full min-h-[calc(100vh-110px)] flex justify-center items-center">
          <Slab
            color={dark.dark ? "#80abf9" : "#1673ff"}
            size="large"
            text="Loading Employees..."
            textColor={dark.dark ? "#fff" : "#000"}
          />
        </div>
      ) : (
        <>
          <section className="bg-bg p-3 sm:p-5 antialiased mt-[110px] min-h-[calc(100vh-110px)]">
            <div className="mx-auto max-w-screen-2xl px-4 lg:px-12">
              <div className="bg-secBg relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="px-4">
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-4 py-3">
                    <div>
                      <nav
                        className="mb-2 flex px-3 py-2 text-gray-700 border border-gray-200 rounded-lg bg-bg dark:border-gray-600"
                        aria-label="Breadcrumb"
                      >
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                          <li className="inline-flex items-center">
                            <a
                              href={Routes.Dashboard.path}
                              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-main transition-all dark:text-gray-300"
                            >
                              Dashboard
                            </a>
                          </li>
                          {searchParams.get("entity") !== null && (
                            <li>
                              <div className="flex items-center">
                                <svg
                                  aria-hidden="true"
                                  className="w-5 h-5 text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <Link
                                  to={`/dashboard/${searchParams.get(
                                    "entity"
                                  )}`}
                                  className="ml-1 text-sm font-medium text-gray-700 hover:text-primary-600 md:ml-2 dark:text-gray-300 dark:hover:text-white"
                                >
                                  {capitalizeFirstLetter(
                                    searchParams.get("entity")!
                                  )}
                                </Link>
                              </div>
                            </li>
                          )}
                          <li aria-current="page">
                            <div className="flex items-center">
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                                All Employees
                              </span>
                            </div>
                          </li>
                        </ol>
                      </nav>
                      <h5>
                        <span className="text-2xl font-bold">All Users</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-bg border-y border-gray-300 dark:border-gray-500 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="p-4">
                        </th>
                        <th scope="col" className="px-4 py-3">
                          User
                        </th>
                        <th scope="col" className="px-4 py-3">
                          User Role
                        </th>
                        <th scope="col" className="px-4 py-3 whitespace-nowrap">
                          
                        </th>
                        <th scope="col" className="px-4 py-3 whitespace-nowrap">
                          Department
                        </th>
                        <th scope="col" className="px-4 py-3 whitespace-nowrap">
                          
                        </th>
                        <th scope="col" className="px-4 py-3 whitespace-nowrap">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allEmployees.slice(employees.offset, employees.offset + employees.limit).map((user, int)  => (
                        <EmployeeCell
                          user={user}
                          selected={selected}
                          setSelected={setSelected}
                          key={int}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <nav
                  className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
                  aria-label="Table navigation"
                >
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Showing
                    <span className="font-semibold text-black">
                      &ensp;
                      {employees.total <= 0 ? 0 : employees.offset + 1}
                      &ensp; -&ensp;
                      {Math.min((employees.offset + employees.limit), employees.total)}
                      &ensp;
                    </span>
                    of
                    <span className="font-semibold text-black">
                      &ensp;{employees.total}
                    </span>
                  </span>
                  <ul className="inline-flex items-stretch -space-x-px">
                    <li>
                      <button
                        onClick={() => {
                          handlePrev();
                        }}
                        disabled={employees.offset === 0}
                        className="flex opacity-50 cursor-not-allowed enabled:cursor-pointer enabled:opacity-100 transition-all items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-secBg rounded-l-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg enabled:hover:border-main dark:border-gray-700"
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          if (employees.offset + employees.limit < employees.total) {
                            handleNext();
                          }
                        }}    
                        disabled={employees.offset + employees.limit >= employees.total}
                        className="flex cursor-not-allowed enabled:cursor-pointer opacity-50 enabled:opacity-100 transition-all items-center justify-center h-full py-1.5 px-3 leading-tight text-black bg-secBg rounded-r-lg border border-gray-300 enabled:hover:bg-main enabled:hover:text-bg enabled:hover:border-main dark:border-gray-700"
                      >
                        <span className="sr-only">Next</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

// Exporting
export default Employees;
