import { useEffect, useState } from "react";

// Helpers
import {
  addEmployee as dispatchEmployeeCreate,
  updateEmployee as dispatchEmployeeUpdate,
  addEmployees,
  Err,
  useAppSelector,
  useAppDispatch,
  employeeError,
  startEmployeeUpdate
} from "@util";
import { useApi } from "@hooks/api";
import { useUser } from "@hooks/user";
import { toast } from "react-toastify";

const useEmployees = () => {
  // The User's Employees
  const { employees, loading, error, total, tempEmployee } = useAppSelector(
    state => state.employees
  );

  // The User's Offset
  const [offset, setOffset] = useState(0);

  // The Limit For The Employees
  const [limit, setLimit] = useState(20);

  const [currentPage, setCurrentPage] = useState(0);

  // The User
  const user = useUser();

  // The API
  const api = useApi();

  // The App Dispatch
  const dispatch = useAppDispatch();

  // Fetching The User Employees If They Dont Exist
  useEffect(() => {
    // Don't Fetch On Load If There Already Employees
    fetchEmployees(offset === 0);
    // eslint-disable-next-line
  }, [offset]);

  /**
   * Create A New Employee
   *
   * @param Employee - The Employee For The API Call
   */
  const addEmployee = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing Default
    event.preventDefault();

    // Making Sure The Employee Exists
    if (tempEmployee === undefined) {
      throw new Error("should not happen");
    }

    // Setting Loading
    dispatch(startEmployeeUpdate());

    // Setting The Toast
    const id = toast.loading("Creating Employee...");

    // Fetch The Employees
    api
      .requestWithJSON<Drivn.User>("/user/invite", true, "POST", tempEmployee)
      .then(([, res]) => {
        // Handling Errors
        if (isError(res)) {
          dispatch(employeeError([res, id]));
          return;
        }

        // Updating The Toast
        toast.update(id, {
          render: "Employee created successfully",
          type: "success",
          autoClose: 5000,
          isLoading: false
        });

        // Set The Employees
        dispatch(dispatchEmployeeCreate(res));
      })
      .catch(err => api.handleErrors(err, employeeError, id));
  };

  const isError = (object: any): object is Err =>
    "relatedVar" in object && "name" in object && "message" in object;

  /**
   * Update An Existing Employee
   *
   * @param update - The Employee Fields To Update
   */
  const updateEmployee = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing Default
    event.preventDefault();

    // Making Sure The Employee Exists
    if (tempEmployee === undefined) {
      throw new Error("should not happen");
    }

    // Setting Loading
    dispatch(startEmployeeUpdate());

    // Setting The Toast
    const id = toast.loading("Updating Employee...");

    // Fetch The Employees
    api
      .requestWithJSON<Drivn.User>(
        `/organization/user/${tempEmployee.uid!}`,
        true,
        "PUT",
        tempEmployee
      )
      .then(([, res]) => {
        // Handling Errors
        if (isError(res)) {
          dispatch(employeeError([res, id]));
          return;
        }

        // Updating The Toast
        toast.update(id, {
          render: "Employee updated successfully",
          type: "success",
          autoClose: 5000,
          isLoading: false
        });

        // Set The Employees
        dispatch(dispatchEmployeeUpdate(res));
      })
      .catch(err => api.handleErrors(err, employeeError, id));
  };

  /**
   * Fetch Employees
   */
  const fetchEmployees = (first: boolean = false) => {
    const totalEmployeesFetched = employees.reduce((sum, set) => sum + set.employees.length, 0);

    // Making Sure The Employees Don't Exist
    if (loading || error !== undefined || totalEmployeesFetched === total) {
      return;
    }

    // Setting Loading
    dispatch(startEmployeeUpdate());

    // Fetch The Employees
    api
      .requestWithJSON<Drivn.EmployeeResponse>(
        `/organization/user/all?limit=${limit}&offset=${offset}`,
        true,
        "GET"
      )
      .then(([, res]) => {
        // eslint-disable-next-line
        console.log('API Response:', res);
        // Handling Errors
        if (res instanceof Err) {
          dispatch(employeeError([res, undefined]));
          return;
        }

        // Set The Employees
        dispatch(addEmployees([res, first]));
      })
      .catch(err => api.handleErrors(err, employeeError));

  };

  return {
    employees,
    loading,
    error,
    user,
    limit,
    total,
    setLimit,
    offset,
    setOffset,
    updateEmployee,
    addEmployee,
    tempEmployee,
    currentPage,
    setCurrentPage
  };
};

// Exporting The Hook
export default useEmployees;
